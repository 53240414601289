<template>
    <div>
      <b-modal
        id="modal-edit-poli"
        size="lg"
        centered
        title="Edit Data Master Poli"
        header-bg-variant="warning"
        header-text-variant="light"
        @hidden="tutupModal"
      >
      <b-form>
          <b-form-group label-cols-md="3">
            <template v-slot:label>
              Nama Poli <span class="text-danger">*</span>
            </template>
            <b-form-input
              :state="checkIfValid('nama_poli')"
              type="text"
              v-model="$v.is_data.nama_poli.$model"
            ></b-form-input>
          </b-form-group>
          <b-form-group label-cols-md="3">
            <template v-slot:label>
              Kode Poli Puskesmas <span class="text-danger">*</span>
            </template>
            <b-form-input
              :state="checkIfValid('kode_poli_puskesmas')"
              type="text"
              v-model="$v.is_data.kode_poli_puskesmas.$model"
            ></b-form-input>
          </b-form-group>
          <!-- <b-form-group label-cols-md="3">
            <template v-slot:label>
              Kode Poli BPJS <span class="text-danger">*</span>
            </template>
            <b-form-select
              v-model="$v.is_data.kode_poli_bpjs.$model"
              :state="checkIfValid('kode_poli_bpjs')"
              :options="list_kode"
            ></b-form-select>
          </b-form-group> -->
          <b-form-group label-cols-md="3">
            <template v-slot:label>
              Poli Sakit <span class="text-danger">*</span>
            </template>
            <b-form-select
              v-model="$v.is_data.poli_sakit.$model"
              :state="checkIfValid('poli_sakit')"
              :options="options"
            ></b-form-select>
          </b-form-group>
          <b-form-group label-cols-md="3">
            <template v-slot:label>
              Laboratorium <span class="text-danger">*</span>
            </template>
            <b-form-select
              v-model="$v.is_data.is_lab.$model"
              :state="checkIfValid('is_lab')"
              :options="options"
            ></b-form-select>
          </b-form-group>
          <b-form-group label-cols-md="3">
            <template v-slot:label>
              Instalasi <span class="text-danger">*</span>
            </template>
            <b-form-select
              :state="checkIfValid('instalasi')"
              :options="options_intalasi"
              v-model="$v.is_data.instalasi.$model"
            ></b-form-select>
          </b-form-group>
        </b-form>
  
        <template #modal-footer>
          <b-button variant="secondary" @click="$bvModal.hide('modal-edit-poli')">
            Batal
          </b-button>
          <b-button
            variant="primary"
            :disabled="busy || !isValid"
            @click="simpan()"
          >
            {{ button }}
          </b-button>
        </template>
      </b-modal>
    </div>
</template>
<script>
  import { validationMixin } from "vuelidate";
  import { required } from "vuelidate/lib/validators";
  export default {
    name: "modalEdit",
    props: ["data_edit", "list_kode"],
    data() {
      return {
        busy: false,
        button: "Simpan",
        is_data: null,
        options: [
            {value: true, text: "Ya"},
            {value: false, text: "Tidak"},
        ],
        options_intalasi: [
          {value: "rajal", text: "RAJAL"},
          {value: "ranap", text: "RANAP"},
          {value: "igd", text: "IGD"},
        ],
      };
    },
    computed: {
      computed_val: {
        get() {
          return this.is_data;
        },
        set(newVal) {
          this.is_data = newVal;
        },
      },
      formString() {
        return JSON.stringify(this.is_data, null, 4);
      },
      isValid() {
        return !this.$v.is_data.$invalid;
      },
      isDirty() {
        return this.$v.is_data.$anyDirty;
      },
    },
    watch: {
      data_edit(newVal) {
        this.is_data = newVal;
      },
    },
  
    mixins: [validationMixin],
    validations: {
        is_data: {
            nama_poli: {
                required,
            },
            kode_poli_puskesmas: {
                required,
            },
            // kode_poli_bpjs: {
            //     required,
            // },
            poli_sakit: {
                required,
            },
            is_lab: {
                required,
            },
            instalasi: {
                required,
            }
        },
    },
    methods: {
      checkIfValid(fieldName) {
        const field = this.$v.is_data[fieldName];
        if (!field.$dirty) {
          return null;
        }
        return !(field.$invalid || field.$model === "");
      },
      simpan() {
        let vm = this;
        if (vm.isValid) {
          vm.busy = true;
          vm.button = "Mohon Tunggu";
          vm.$axios
            .post("/ms_poli/update", {
              id: vm.is_data.ms_poli_id,
              nama_poli: vm.is_data.nama_poli,
              kode_poli_puskesmas: vm.is_data.kode_poli_puskesmas,
              kode_poli_bpjs: vm.is_data.kode_poli_bpjs,
              poli_sakit: vm.is_data.poli_sakit,
              is_lab: vm.is_data.is_lab,
              instalasi: vm.is_data.instalasi
            })
            .then((res) => {
              if (res.data.message == "sukses") {
                vm.button = "Simpan";
                vm.busy = false;
                vm.$emit("alertFromChild", {
                  variant: "success",
                  msg: "BERHASIL MENGUBAH DATA",
                  showing: true,
                });
                vm.$bvModal.hide("modal-edit-poli");
                vm.resetModal();
              } else {
                vm.button = "Simpan";
                vm.busy = false;
                vm.$emit("alertFromChild", {
                  variant: "danger",
                  msg: (res.data.message).toUpperCase(),
                  showing: true,
                });
              }
            })
            .catch(() => {
              vm.button = "Simpan";
              vm.busy = false;
              vm.$emit("alertFromChild", {
                variant: "danger",
                msg: "TERJADI KESALAHAN PADA SERVER",
                showing: true,
              });
            });
        }
      },
      tutupModal() {
        this.$emit("tutupModal");
      },
      resetModal() {
        this.$v.$reset();
        this.is_data = {
          nama_poli: "",
          kode_poli_puskesmas: "",
          kode_poli_bpjs: "",
          poli_sakit: null
        };
      },
    },
  };
  </script>
  