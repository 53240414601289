<template>
    <div>
      <b-modal
        id="modal-input-poli"
        size="lg"
        centered
        title="Tambah Data Master Poli"
        header-bg-variant="primary"
        header-text-variant="light"
        @hidden="resetModal"
      >
        <b-form>
          <b-form-group label-cols-md="3">
            <template v-slot:label>
              Nama Poli <span class="text-danger">*</span>
            </template>
            <b-form-input
              :state="checkIfValid('nama_poli')"
              type="text"
              v-model="$v.is_data.nama_poli.$model"
            ></b-form-input>
          </b-form-group>
          <b-form-group label-cols-md="3">
            <template v-slot:label>
              Kode Poli Puskesmas <span class="text-danger">*</span>
            </template>
            <b-form-input
              :state="checkIfValid('kode_poli_puskesmas')"
              type="text"
              v-model="$v.is_data.kode_poli_puskesmas.$model"
            ></b-form-input>
          </b-form-group>
          <!-- <b-form-group label-cols-md="3">
            <template v-slot:label>
              Kode Poli BPJS <span class="text-danger">*</span>
            </template>
            <b-form-select
              v-model="$v.is_data.kode_poli_bpjs.$model"
              :state="checkIfValid('kode_poli_bpjs')"
              :options="list_kode"
            ></b-form-select>
          </b-form-group> -->
          <!-- <b-form-group label-cols-md="3">
            <template v-slot:label>
              Kode Poli BPJS
            </template>
             <Multiselect
              :options="list_kode"
              :multiple="false"
              :searchable="true"
              :close-on-select="true"
              :show-labels="false"
              v-model="is_data.kode_bpjs"
              placeholder="-- Pilih --"
              label="nama"
              size="sm"
            ></Multiselect>
          </b-form-group> -->
          <b-form-group label-cols-md="3">
            <template v-slot:label>
              Poli Sakit <span class="text-danger">*</span>
            </template>
            <b-form-select
              v-model="$v.is_data.poli_sakit.$model"
              :state="checkIfValid('poli_sakit')"
              :options="options"
            ></b-form-select>
          </b-form-group>
          <b-form-group label-cols-md="3">
            <template v-slot:label>
              Laboratorium <span class="text-danger">*</span>
            </template>
            <b-form-select
              v-model="$v.is_data.is_lab.$model"
              :state="checkIfValid('is_lab')"
              :options="options"
            ></b-form-select>
          </b-form-group>
          <b-form-group label-cols-md="3">
            <template v-slot:label>
              Instalasi <span class="text-danger">*</span>
            </template>
            <b-form-select
              :state="checkIfValid('instalasi')"
              :options="options_intalasi"
              v-model="$v.is_data.instalasi.$model"
            ></b-form-select>
          </b-form-group>
        </b-form>
        <!-- <img :src="src1" /> -->
        <template #modal-footer>
          <b-button variant="secondary" @click="$bvModal.hide('modal-input-poli')">
            Batal
          </b-button>
          <b-button
            variant="primary"
            :disabled="busy || !isValid"
            @click="simpan()"
            >{{ button }}</b-button
          >
        </template>
      </b-modal>
    </div>
</template>
  
<script>
  import { validationMixin } from "vuelidate";
  import { required } from "vuelidate/lib/validators";
  
  export default {
    name: "modalInput",
    props: ["list_kode"],
    data() {
      return {
        is_data: {
          nama_poli: "",
          kode_poli_puskesmas: "",
          kode_poli_bpjs: "",
          nama_poli_bpjs: "",
          poli_sakit: null,
          is_lab: null,
          instalasi: null,
          kode_bpjs: null,
        },
        options: [
            {value: true, text: "Ya"},
            {value: false, text: "Tidak"},
        ],
        options_intalasi: [
          {value: "rajal", text: "RAJAL"},
          {value: "ranap", text: "RANAP"},
          {value: "igd", text: "IGD"},
        ],
        selected: null,
        busy: false,
        button: "Simpan",
      };
    },
    computed: {
      formString() {
        return JSON.stringify(this.is_data, null, 4);
      },
      isValid() {
        return !this.$v.is_data.$invalid;
      },
      isDirty() {
        return this.$v.is_data.$anyDirty;
      },
    },
    mixins: [validationMixin],
    validations: {
      is_data: {
        nama_poli: {
          required,
        },
        kode_poli_puskesmas: {
          required,
        },
        // kode_poli_bpjs: {
          
        // },
        poli_sakit: {
          required,
        },
        is_lab: {
          required,
        },
        instalasi: {
          required,
        }
      },
    },
    methods: {
      checkIfValid(fieldName) {
        const field = this.$v.is_data[fieldName];
        if (!field.$dirty) {
          return null;
        }
        return !(field.$invalid || field.$model === "");
      },
      simpan() {
        let vm = this;
        vm.busy = true;
        vm.button = "Mohon Tunggu";
        // if(vm.is_data.kode_bpjs){
        //   vm.is_data.kode_poli_bpjs = vm.is_data.kode_bpjs.kdPoli
        //   vm.is_data.nama_poli_bpjs = vm.is_data.kode_bpjs.nmPoli
        // }
        if(vm.is_data.nama_poli == "POLI UMUM"){
          vm.is_data.kode_poli_bpjs = "001"
          vm.is_data.nama_poli_bpjs = "POLI UMUM"
        }else if(vm.is_data.nama_poli == "POLI UMUM 2"){
          vm.is_data.kode_poli_bpjs = "001"
          vm.is_data.nama_poli_bpjs = "POLI UMUM"
        }else if(vm.is_data.nama_poli == "POLI KIA"){
          vm.is_data.kode_poli_bpjs = "003"
          vm.is_data.nama_poli_bpjs = "POLI KIA"
        }else if(vm.is_data.nama_poli == "POLI ANAK"){
          vm.is_data.kode_poli_bpjs = "003"
          vm.is_data.nama_poli_bpjs = "POLI KIA"
        }else if(vm.is_data.nama_poli == "POLI KB"){
          vm.is_data.kode_poli_bpjs = "008"
          vm.is_data.nama_poli_bpjs = "POLI KB"
        }else if(vm.is_data.nama_poli == "LABORATORIUM"){
          vm.is_data.kode_poli_bpjs = "004"
          vm.is_data.nama_poli_bpjs = "LABORATORIUM"
        }else if(vm.is_data.nama_poli == "POLI GIZI"){
          vm.is_data.kode_poli_bpjs = "021"
          vm.is_data.nama_poli_bpjs = "KONSELING"
        }else if(vm.is_data.nama_poli == "POLI MTBS"){
          vm.is_data.kode_poli_bpjs = "003"
          vm.is_data.nama_poli_bpjs = "POLI KIA"
        }else if(vm.is_data.nama_poli == "POLI GIGI"){
          vm.is_data.kode_poli_bpjs = "002"
          vm.is_data.nama_poli_bpjs = "POLI GIGI & MULUT"
        }else if(vm.is_data.nama_poli == "LANSIA"){
          vm.is_data.kode_poli_bpjs = "034"
          vm.is_data.nama_poli_bpjs = "Edukasi-Kelompok Prolanis"
        }else if(vm.is_data.nama_poli == "IMUNISASI"){
          vm.is_data.kode_poli_bpjs = "023"
          vm.is_data.nama_poli_bpjs = "Imunisasi (BCG)"
        }
        vm.$axios
          .post("/ms_poli/register", vm.is_data)
          .then((res) => {
            console.log(res);
            if (res.data.message == "sukses") {
              vm.button = "Simpan";
              vm.busy = false;
              vm.$v.$reset();
              vm.$emit("alertFromChild", {
                variant: "success",
                msg: "BERHASIL MENDAFTARKAN DATA",
                showing: true,
              });
              vm.$bvModal.hide("modal-input-poli");
              vm.resetModal();
            } else {
              vm.button = "Simpan";
              vm.busy = false;
              vm.$emit("alertFromChild", {
                variant: "danger",
                msg: (res.data.message).toUpperCase(),
                showing: true,
              });
            }
          })
          .catch(() => {
            vm.button = "Simpan";
            vm.busy = false;
            vm.$emit("alertFromChild", {
              variant: "danger",
              msg: "TERJADI KESALAHAN PADA SERVER",
              showing: true,
            });
          });
      },
      resetModal() {
        this.$v.$reset();
        this.is_data = {
          nama_poli: "",
          kode_poli_puskesmas: "",
          kode_poli_bpjs: "",
          poli_sakit: null,
          is_lab: null,
          instalasi: null,
        };
      },
    },
  };
  </script>
  